var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "show-search-button": false,
          },
          on: { reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1 btn-sm-block",
                    attrs: {
                      id: "download",
                      variant: "primary",
                      icon: "download",
                      busy: _vm.fetching,
                      text: _vm.$t("Baixar Relatório"),
                    },
                    on: { click: _vm.downloadReport },
                  }),
                  _c("a", {
                    ref: "downloadLink",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "filter-store",
                          required: false,
                          placeholder: _vm.$t("Todas"),
                        },
                        model: {
                          value: _vm.filtersToReport.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "storeId", $$v)
                          },
                          expression: "filtersToReport.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "expense-period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                          "time-picker": false,
                          opens: "left",
                          validation: "required",
                        },
                        model: {
                          value: _vm.filtersToReport.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "period", $$v)
                          },
                          expression: "filtersToReport.period",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.$can("Create", "Auth")
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Adicionar ReconciliationPedingItens"),
            },
            on: { click: _vm.onCreateReconciliationPedingItens },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }