<template>
  <section>
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :show-search-button="false"
      @reset="resetFiltersLocal"
    >
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          @click="downloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="filter-store"
              v-model="filtersToReport.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="expense-period"
              v-model="filtersToReport.period"
              type="date-range-picker"
              :label="$t('Período')"
              :time-picker="false"
              opens="left"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>
    <fab
      v-if="$can('Create', 'Auth')"
      :main-tooltip="$t('Adicionar ReconciliationPedingItens')"
      @click="onCreateReconciliationPedingItens"
    />
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { EFilters, EButton, EStoreCombo } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes, downloader } from '@/mixins'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    EButton,
    EStoreCombo,
    fab,
    EFilters,
  },

  mixins: [statusTypes, downloader],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/reports/reconciliation/reconciliationPedingItens', [
      'reconciliationPedingItenss',
      'reportData',
      'filtersToReport',
      'paging',
      'sorting',
      'filters',
    ]),
  },

  methods: {
    ...mapActions('pages/reports/reconciliation/reconciliationPedingItens', [
      'generateReport',
      'cleanFilterToReport',
      'resetFilters',
    ]),

    resetFiltersLocal() {
      this.cleanFilterToReport()
    },

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true

          const saveReportData = await this.generateReport()

          // Get the download link element from the template
          // const { downloadLink } = this.$refs

          if (saveReportData) {
            // Set the URL and download attributes of the link
            this.forceFileDownload(
              saveReportData,
              `ConciliacaoTransacoesIgnoradas-Loja_${this.filtersToReport.storeId}-Data_${this.filtersToReport.period.startDate}_ate_${this.filtersToReport.period.endDate}.xlsx`
            )
            // downloadLink.href = this.reportData
            // downloadLink.download = `ConciliacaoTransacoesIgnoradas-Loja_${this.filtersToReport.storeId}-Data_${this.filtersToReport.period.startDate}_ate_${this.filtersToReport.period.endDate}.xlsx`

            // // Click the link to start the download
            // downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>
